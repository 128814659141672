// ======================================================================== //
//
// Name:            Service
// Description:     Styles specific to the service page
//
// ======================================================================== //

// Variables
// ======================================================================== //

// ======================================================================== //
// Page: Service
// ======================================================================== //

.page-Service {
  #service-top,
  #service-bottom {
    .link {
      padding-left: 12px;
      position: relative;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        left: 0;
        top: 5.5px;
        border-left: 2px solid $primary;
        border-bottom: 2px solid $primary;
        transform: rotate(45deg);
      }
      &:hover {
        &:before {
          border-color: $green-dark;
        }
      }
    }
  }

  #service-top {
    .col-12 {
      margin-bottom: -80px;
      @media screen and (max-width: map-get($css-breakpoints, "sm")) {
        margin-bottom: 0;
      }
    }
  }
  #service-banner {
    width: 100%;
    height: 0;
    padding-top: 40%;
  }
  #latest-news {
    padding-bottom: 140px !important;
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      padding-bottom: 120px !important;
    }
  }
  #service-bottom {
    margin-top: -80px;
    margin-bottom: 90px;
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      //margin-top: 0;
      //margin-bottom: 10px;
    }
    .link {
      padding: 25px 120px 25px 40px;
      @media screen and (max-width: map-get($css-breakpoints, "sm")) {
        padding-right: 60px;
      }
      &:before {
        left: 30px;
        top: 31.5px;
      }
    }
  }
}
