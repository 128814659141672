// ======================================================================== //
//
// Name:            About
// Description:     Styles specific to the about page
//
// ======================================================================== //

// Variables
// ======================================================================== //

// ======================================================================== //
// Page: About
// ======================================================================== //

.page-About {
    #overview {
        padding: 190px 0 105px 0;
        @media screen and (max-width: map-get($css-breakpoints, "lg")) {
            padding-top: 240px;
            padding-bottom: 80px;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            padding-top: 160px;
            padding-bottom: 30px;
        }
        .banner-image {
            right: 0;
            top: 20px;
            left: 50%;
            bottom: 40px;
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                top: 80px;
                left: 0;
                bottom: initial;
                height: 400px;
            }
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                top: 0;
                height: 200px;
            }
        }
    }
    #our-values {
        padding-top: 100px;
        padding-bottom: 60px;
        @media screen and (max-width: map-get($css-breakpoints, "lg")) {
            padding-top: 60px;
            padding-bottom: 40px;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    #meet-the-team {
        padding-bottom: 80px;
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
            padding-bottom: 40px;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            padding-bottom: 0;
        }
        .row .col-12 {
            &:nth-child(even) {
                top: 80px;
                @media screen and (max-width: map-get($css-breakpoints, "md")) {
                    top: 0;
                }
            }
        }
        .card {
            width: 100%;
            max-width: 420px;
            left: 20px;
            margin-top: -80px;
            margin-bottom: 40px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                left: 0;
                margin-left: 15px;
                margin-right: 15px;
                margin-top: -40px;
                width: initial;
            }
        }
        .modal {
            .modal-content {
                border: none !important;
            }
            .modal-dialog {
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    margin: 0 !important;
                }
            }
            .modal-body {
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    background-color: $primary;
                    color: white !important;
                    .caption,
                    h2, h3, h4,
                    .h2, .h3, .h4 {
                        color: white !important;
                    }
                }
            }
            .close {
                cursor: pointer;
                position: absolute;
                right: -30px;
                top: -30px;
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    right: 5px;
                }
            }
            .mobile {
                position: relative;
                justify-content: center;
                align-items: center;
            }
            .next,
            .prev {
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    position: relative;
                    transform: none;
                }
            }
            .next {
                right: -80px;
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    right: initial;
                }
            }
            .prev {
                left: -80px;
                @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                    left: initial;
                }
            }
        }
    }
}
.modal-backdrop {
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
        background-color: $primary;
        &.show {
            opacity: 1 !important;
        }
    }
}
