#insurance-partners,
#partners {
  overflow: hidden;
  .gutter-line {
    top: 67px;
    @media screen and (max-width: map-get($css-breakpoints, "md")) {
      top: 37px;
    }
  }
  .side-nav {
    top: 60px;
    @media screen and (max-width: map-get($css-breakpoints, "md")) {
      top: 30px;
    }
  }
  .slider {
    .slide {
      height: 120px;
      filter: grayscale(100%);
      @media screen and (max-width: map-get($css-breakpoints, "md")) {
        height: 240px;
        div {
          flex: 0 0 33.33333%;
          width: 33.3333%;
        }
      }
    }
    @media screen and (max-width: 1300px) {
      margin: 0 40px;
    }
    @media screen and (max-width: map-get($css-breakpoints, "md")) {
      margin: 0;
      padding-bottom: 15px;
    }
  }
  .controls {
    left: -50px;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    height: 38px;
    pointer-events: none;
    @media screen and (max-width: 1300px) {
      left: -10px;
      right: -10px;
    }
    @media screen and (max-width: map-get($css-breakpoints, "xl")) {
      left: 0;
      right: 0;
    }
    @media screen and (max-width: map-get($css-breakpoints, "md")) {
      transform: none;
      top: initial;
      left: initial;
      bottom: -35px;
      right: 25px;
      height: 58px;
      width: 125px;
    }
    a {
      pointer-events: all;
      position: absolute;
      display: block;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 3px solid #979797;
      cursor: pointer;
      @media screen and (max-width: map-get($css-breakpoints, "md")) {
        width: 58px;
        height: 58px;
        border: 4px solid #979797;
      }
      &.next {
        right: 0;
      }
      &.prev {
        left: 0;
      }
      span {
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%) rotate(45deg);
        border-right: 2px solid #979797;
        border-top: 2px solid #979797;
        &.prev {
          transform: translate(-50%, -50%) rotate(-135deg);
          left: 55%;
        }
        @media screen and (max-width: map-get($css-breakpoints, "md")) {
          border-width: 3px;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

#insurance-partners {
  .slider {
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .controls {
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      left: 50%;
      bottom: -45px;
      transform: translateX(-50%);
    }
  }
}