.module-quote {
  .quote {
    position: relative;
    &::before {
      content: '“';
      position: absolute;
      left: 10px;
      top: 0;
    }
  }
}

.module-video {
  .embed-wrapper {
    overflow: hidden !important;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  .embed-responsive {
    transition: all 0.5s;
    &:hover {
      box-shadow: 0 36px 44px -30px rgba(0,0,0,0.40);
    }
  }
}