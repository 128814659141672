%btn-arrow {
  position: relative;
  @media screen and (max-width: map-get($css-breakpoints, "sm")) {
    padding-right: 60px;
  }
  &::before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: white;
    content: '';
    display: inline-block;
    height: 8px;
    position: absolute;
    top: 45%;
    vertical-align: top;
    width: 8px;

    right: 20px;
    transform: rotate(45deg) translateY(-50%);
  }
}

.btn-arrow {
  @extend %btn-arrow;
  padding-right: 90px;
}

.btn-arrow-med {
  @extend %btn-arrow;
  padding-right: 60px;
}

.btn-arrow-sml {
  @extend %btn-arrow;
  padding-right: 40px;
}