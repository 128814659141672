@import "~jquery-ui-dist/jquery-ui.min.css";
@import "~jquery-ui-dist/jquery-ui.theme.min.css";

// Set above labels
#ui-datepicker-div {
  z-index: 99 !important;
}

/**
 * Override Icon Paths
 */
.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("/themes/zimple/assets/images/vendor/jquery-ui-dist/ui-icons_444444_256x240.png");
}
.ui-widget-header .ui-icon {
  background-image: url("/themes/zimple/assets/images/vendor/jquery-ui-dist/ui-icons_444444_256x240.png");
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
  background-image: url("/themes/zimple/assets/images/vendor/jquery-ui-dist/ui-icons_555555_256x240.png");
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
  background-image: url("/themes/zimple/assets/images/vendor/jquery-ui-dist/ui-icons_ffffff_256x240.png");
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
  background-image: url("/themes/zimple/assets/images/vendor/jquery-ui-dist/ui-icons_777620_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("/themes/zimple/assets/images/vendor/jquery-ui-dist/ui-icons_cc0000_256x240.png");
}
.ui-button .ui-icon {
  background-image: url("/themes/zimple/assets/images/vendor/jquery-ui-dist/ui-icons_777777_256x240.png");
}