#testimonials {
  .gutter-line {
    top: 107px;
    @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
      top: 47px;
    }
  }
  .side-nav {
    position: absolute;
    top: 100px;
    left: 0;
    @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
      top: 40px;
    }
  }
  .controls {
    @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
      padding-bottom: 40px;
      > div {
        padding-top: 20px;
        border-top: 1px solid #979797;
      }
    }
    a {
      position: relative;
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border: 4px solid #979797;
      margin: 10px 0;
      @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
        margin: 0 10px;
      }
      span {
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%) rotate(45deg);
        border-right: 3px solid $white;
        border-top: 3px solid $white;
        &.prev {
          transform: translate(-50%, -50%) rotate(-135deg);
          left: 55%;
        }
      }
    }
  }
  .quote {
    position: absolute;
    &.start {
      top: 10px;
      left: 46px;
      @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
        left: 0;
        top: 35px;
      }
      @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
        left: -15px;
      }
    }
    &.end {
      bottom: 10px;
      right: 0;
      @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
        bottom: 40px;
      }
      @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
        right: 15px;
      }
    }
  }
  #testimonials-carousel {
    .carousel-inner {
      padding: 100px 0 90px 0;
      @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
        padding: 80px 0 0 0;
      }
      .carousel-item {
        padding-left: 30px;
        border-left: 1px solid #979797;
        @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
          border: none;
          padding-left: 0;
        }
        p {
          color: $white !important;
        }
      }
    }
  }
}