// ======================================================================== //
//
// Name:            News
// Description:     Styles specific to the news pages
//
// ======================================================================== //

// Variables
// ======================================================================== //

// ======================================================================== //
// Page: News
// ======================================================================== //

.page-News {
    #featured-article {
        padding: 190px 0 105px 0;
        @media screen and (max-width: map-get($css-breakpoints, "lg")) {
            padding-top: 240px;
            padding-bottom: 0;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            padding-top: 160px;
        }
        .banner-image {
            right: 0;
            top: 60px;
            left: 50%;
            bottom: 0;
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                top: 0;
                left: 0;
                bottom: initial;
                height: 400px;
            }
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                height: 200px;
            }
        }
    }
    #pagination {
        .pagination {
            li {
                background-color: $white;
                width: 38px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                    text-decoration: none;
                    color: $primary;
                    font-size: 15px;
                    font-weight: bold;
                    letter-spacing: 1.18px;
                    line-height: 20px;
                }
                &.active {
                    background-color: $primary;
                    span {
                        color: $white;
                        font-size: 15px;
                        font-weight: bold;
                        letter-spacing: 1.18px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.page-newsArticle {
    #article-top,
    #article-bottom {
        .link {
            padding-left: 12px;
            position: relative;
            &:before {
                content: '';
                width: 6px;
                height: 6px;
                position: absolute;
                left: 0;
                top: 5.5px;
                border-left: 2px solid $primary;
                border-bottom: 2px solid $primary;
                transform: rotate(45deg);
            }
            &:hover {
                &:before {
                    border-color: $green-dark;
                }
            }
        }
    }
    #article-top {
        .col-12 {
            margin-bottom: -80px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                margin-bottom: 0;
            }
        }
    }
    #article-banner {
        width: 100%;
        height: 0;
        padding-top: 40%;
    }
    #article-bottom {
        .link {
            display: inline-block;
            padding: 25px 120px 25px 40px;
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                padding-right: 60px;
            }
            &:before {
                left: 30px;
                top: 35.5px;
            }
        }
    }
}