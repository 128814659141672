// ======================================================================== //
//
// Name:            Home
// Description:     Styles specific to the home page
//
// ======================================================================== //

// Variables
// ======================================================================== //

// ======================================================================== //
// Page: Home
// ======================================================================== //

// Section: Banner
// ------------------------------------------------------------------------ //

.page-Home {
    #home-banner {
        .row {
            padding: 200px 0;
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                padding: 120px 0 60px 0;
            }
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                padding: 120px 0 20px 0;
            }
        }
        .content {
            padding: 85px 230px 80px 80px;
            @media screen and (max-width: map-get($css-breakpoints, "xl")) {
                padding: 85px 170px 80px 80px;
            }
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                padding: 60px 120px 60px 60px;
            }
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                padding: 20px;
            }
            .copy {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    #home-services {
        .service-image {
            position: relative;
            left: 20px;
            top: -20px;
        }
        .service-list {
            .service-button {
                box-shadow: 0 3px 11px 0 rgba(0,0,0,0.1);
                cursor: pointer;
                .icon {
                    box-shadow: 0 3px 11px 0 rgba(0,0,0,0.1);
                    width: 120px;
                }
                &.active {
                    @media screen and (min-width: map-get($css-breakpoints, "md")) {
                        background: linear-gradient(74.05deg, #083333 0%, #155456 100%);
                        &:before {
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-bottom: 10px solid transparent;  /* left arrow slant */
                            border-top: 10px solid transparent; /* right arrow slant */
                            border-right: 15px solid #083333; /* bottom, add background color here */
                            left: -30px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                            &:before {
                                border-color: transparent;
                            }
                        }
                        .icon {
                            box-shadow: none;
                            img {
                                filter: brightness(0) invert(1);
                            }
                        }
                        p {
                            color: white !important;
                        }
                    }
                }
            }
            .service-button,
            .service-button .icon,
            .service-button img,
            .service-button p {
                transition: all 0.2s;
            }
        }
    }
}
