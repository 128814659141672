footer {
  @media screen and (max-width: map-get($css-breakpoints, "sm")) {
    background: white;
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1400px;
    }
  }
  .logos {
    position: relative;
    left: -40px;
    top: -50px;
    margin-bottom: -20px;
    margin-right: -40px;
    @media screen and (max-width: map-get($css-breakpoints, "lg")) {
      left: 0;
      margin-right: 0;
    }
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      top: 0;
      margin-bottom: 0;
      box-shadow: none !important;
    }
    .logo {
      padding: 0 20px;
      &:first-child {
        border-right: 1px solid rgba(151,151,151,0.61);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .top {
    border-bottom: 1px solid #DEDEDE;
    .d-flex.flex-row {
      @media screen and (max-width: 1300px) {
        flex-direction: column !important;
      }
    }
    .socials {
      position: relative;
      @media screen and (max-width: map-get($css-breakpoints, "lg")) {
        width: 100%;
        justify-content: center;
      }
      .caption {
        position: absolute;
        right: 8px;
        top: -25px;
        margin: 0 !important;
        @media screen and (max-width: map-get($css-breakpoints, "lg")) {
          left: 0;
          right: 0;
        }
      }
    }
  }
  .bottom {
    .nav-item {
      font-family: $headings-font-family;
      font-weight: 700;
      padding-right: 25px;
    }
  }
}