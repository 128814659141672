header {
  .icon-bar {
    width: 32px;
    height: 3px;
    background-color: $black;
    display: block;
    transition: all 0.2s;
    margin: 6px 0
  }
  .navbar-toggler {
    border: none;
    background: transparent !important;
    span {
      position: relative;
    }
  }
  .navbar-toggler .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
    left: 5px;
  }
  .navbar-toggler .middle-bar {
    opacity: 0;
  }
  .navbar-toggler .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    left: 4.5px;
  }
  .navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
    left: 0;
  }
  .navbar-toggler.collapsed .middle-bar {
    opacity: 1;
  }
  .navbar-toggler.collapsed .bottom-bar {
    transform: rotate(0);
    left: 0;
  }

  .navbar-brand {
    &.main-logo {
      padding-bottom: 15px;
      @media screen and (max-width: map-get($css-breakpoints, "sm")) {
        //padding-bottom: 10px;
      }
    }
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      max-width: 120px;
      img {
        width: 100%;
      }
    }
  }

  .navbar-collapse {
    z-index: 10;
    @media screen and (max-width: map-get($css-breakpoints, "lg")) {
      margin: 0 -9rem;
    }
    @media screen and (max-width: map-get($css-breakpoints, "md")) {
      margin: 0 -7rem;
    }
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      margin: 0 -1rem;
    }
    .navbar-nav {
      @media screen and (max-width: map-get($css-breakpoints, "lg")) {
        background: $primary;
      }
      .nav-item {
        font-family: $headings-font-family;
        font-size: 16px;
        @media screen and (max-width: map-get($css-breakpoints, "lg")) {
          border-bottom: 1px solid #196B71;
          height: 60px;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
          padding: 0 30px;
        }
        &.active {
          position: relative;
          .nav-link {
            color: $primary !important;
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
              color: darken($primary, 15%) !important;
            }
          }
          &:after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            bottom: -6px;
            width: 6px;
            height: 6px;
            border-right: 1px solid $primary;
            border-bottom: 1px solid $primary;
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
              display: none;
            }
          }
        }
        .nav-link {
          @media screen and (max-width: map-get($css-breakpoints, "lg")) {
            padding: 1rem 0;
            margin: 0 auto;
            width: 100%;
            max-width: map-get($container-max-widths, "md");
            color: #FFFFFF;
            font-size: 20px;
            letter-spacing: 0.51px;
            line-height: 24px;
          }
          @media screen and (max-width: map-get($css-breakpoints, "md")) {
            max-width: map-get($container-max-widths, "sm");
          }
          @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            max-width: map-get($container-max-widths, "sm");
          }
        }
      }
    }
    .nav-bottom {
      @media screen and (max-width: map-get($css-breakpoints, "lg")) {
        min-height: 60vh;
      }
      .social {
        width: 45px;
        img {
          width: 100%;
        }
      }
    }
  }
}