// ======================================================================== //
//
// Name:            Sidenav
// Description:     Styles specific to the side nav's
//
// ======================================================================== //

// Variables
// ======================================================================== //


.side-nav-static {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 180px;
  padding-top: 5px;
  background-color: white;
  &.fixed {
    position: fixed;
    top: 30px;
  }
  @media screen and (max-width: map-get($css-breakpoints, "lg")) {
    top: 120px !important;
    position: absolute !important;
  }
  .list-group {
    .list-group-item {
      width: auto !important;
      text-align: center;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 1.38px;
      line-height: 14px;
      color: $gray-600;
      padding: 10px 5px;
      text-transform: uppercase;
      white-space: nowrap;
      border: none;
      border-bottom: 1px solid $primary !important;
      border-radius: 0 !important;
      &.active {
        color: $primary;
        position: relative;
        &:after {
          content: '';
          border: 1px solid $primary;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          position: absolute;
          bottom: -4.5px;
          background-color: $white;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .gutter-line {
    position: relative;
    top: 17px;
  }
}

.gutter-line {
  left: 0;
  height: 1px;
  width: calc(50vw - (#{map-get($container-max-widths, "sm")} / 2));
  @media screen and (min-width: map-get($css-breakpoints, "md")) {
    width: calc(50vw - (#{map-get($container-max-widths, "md")} / 2));
  }
  @media screen and (min-width: map-get($css-breakpoints, "lg")) {
    width: calc(50vw - (#{map-get($container-max-widths, "lg")} / 2));
  }
  @media screen and (min-width: map-get($css-breakpoints, "xl")) {
    width: calc(50vw - (#{map-get($container-max-widths, "xl")} / 2));
  }

  &.white {
    background-color: $white;
  }
  &.primary {
    background-color: $primary;
  }
  &.black {
    background-color: $black;
  }
}

.side-nav-container {
  .link {
    line-height: 12px;
  }
}

.side-nav {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 0;
  .line {
    width: 40px;
    height: 1px;
    display: block;
    @media screen and (max-width: map-get($css-breakpoints, "lg")) {
      width: 30px;
    }
  }
  .circle {
    display: block;
    border: 1px solid $white;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
  p {
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 1.98px;
    line-height: 15px;
    padding-left: 15px;
    text-transform: uppercase;
  }

  &.white {
    .line {
      background-color: $white;
    }
    .circle {
      border-color: $white;
    }
    p {
      color: $white;
    }
  }
  &.primary {
    .line {
      background-color: $primary;
    }
    .circle {
      border-color: $primary;
    }
    p {
      color: $primary;
    }
  }
  &.black {
    .line {
      background-color: $black;
    }
    .circle {
      border-color: $black;
    }
    p {
      color: $gray-600;
    }
  }
}