// ======================================================================== //
//
// Name:            About
// Description:     Styles specific to the about page
//
// ======================================================================== //

// Variables
// ======================================================================== //

// ======================================================================== //
// Page: About
// ======================================================================== //

.page-Contact {
  #contact {
    padding: 190px 0 105px 0;
    @media screen and (max-width: map-get($css-breakpoints, "lg")) {
      padding-top: 240px;
      padding-bottom: 80px;
    }
    @media screen and (max-width: map-get($css-breakpoints, "sm")) {
      padding-top: 160px;
      padding-bottom: 30px;
    }

    .banner-image {
      right: 0;
      top: 60px;
      left: 45%;
      bottom: 40px;
      @media screen and (max-width: map-get($css-breakpoints, "lg")) {
        top: 80px;
        left: 0;
        bottom: initial;
        height: 400px;
      }
      @media screen and (max-width: map-get($css-breakpoints, "sm")) {
        top: 0;
        height: 200px;
      }
    }
  }
}
