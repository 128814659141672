h1,
.h1 {
  font-size: 50px;
  letter-spacing: -1.1px;
  line-height: 58px;
  @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
    font-size: 26px;
    letter-spacing: -0.57px;
    line-height: 30px;
  }
}
h2,
.h2 {
  font-size: 30px;
  letter-spacing: -0.66px;
  line-height: 35px;
}
h3,
.h3 {
  font-size: 24px;
  letter-spacing: -0.53px;
  line-height: 32px;
}
h4,
.h4 {
  font-size: 18px;
  letter-spacing: -0.54px;
  line-height: 28px;
}
h5,
.h5 {

}

.copy {
  h4 {
    color: $gray-700;
  }
  .oc-text-gray {
    font-weight: bold;
    font-size: 14px;
    line-height: 27px;
    color: $gray-600;
    @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
      margin-bottom: 0;
    }
  }
}

.quote {
  font-size: 22px;
  line-height: 30px;
}

.roboto {
  font-family: $headings-font-family;
}

.caption {
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  color: $gray-600;
  &.color-primary {
    color: $primary;
  }
}

.link {
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
}