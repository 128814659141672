form {
  // Add bootstrap themed errors
  .form-field-error-label {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .fade:not(.show) {
    display: none;
  }

  .form-group {
    position:relative;
    label {
      pointer-events: none;
      position: absolute;
      left: 15px;
      top: 10px;
      z-index: 10;
      transition: transform 150ms ease-out, font-size 150ms ease-out;
      font-size: 15px;
      &.required::after {
        content: '*';
        position: relative;
        top: -1px;
        right: 0;
      }
    }
    &.filled,
    &.focused {
      label {
        transform: translateY(-125%);
        font-size: 11px;
      }
    }

    input,
    select,
    textarea {
      height: 42px;
      background-color: transparent !important;
      border: none;
      border-bottom: 1px solid rgba(155,155,155,0.53);
      border-radius: 0 !important;
    }

    &.clock,
    &.calendar,
    &.select {
      position: relative;
      &::after {
        pointer-events: none;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 15px;
        top: 8px;
      }
    }

    &.select {
      &::after {
        content: url("/themes/zimple/assets/images/select-arrow.svg");
      }
    }
    &.clock {
      &::after {
        content: url("/themes/zimple/assets/images/clock.svg");
      }
    }
    &.calendar {
      &::after {
        content: url("/themes/zimple/assets/images/calendar.svg");
      }
    }

    &.disabled {
      opacity: 0.6;
      input,
      select {
        pointer-events: none;
      }
    }

    select {
      appearance: none;
    }
    select::-ms-expand {
      display: none;
    }

    textarea {
      min-height: 200px;
    }
  }
}