// ======================================================================== //
// Your mixins below
// ======================================================================== //

//
// Type
// --------------------------------------------------


//
// Gradient
// --------------------------------------------------
%gradient {
  position: relative;
  opacity: 0.88;
  background-image: linear-gradient(130deg, rgba(232,159,12,0.74) 0%, rgba(232,159,12,0.00) 100%);
  width: 100%;
  height: 100%;
}

//
// Shadows
// --------------------------------------------------
%box-shadow-1 {
  box-shadow: 0 26px 40px 0 rgba(0,0,0,0.22);
}
%box-shadow-2 {
  box-shadow: 0 26px 45px 0 rgba(0,0,0,0.28);
}
%box-shadow-3 {
  box-shadow: 0 46px 100px 0 rgba(0,0,0,0.27);
}

//
// Colors
// --------------------------------------------------

@each $color in $colors {
  .color-#{nth($color, 1)} {
    color: nth($color, 2);
  }
}

@each $color in $colors {
  .bg-#{nth($color, 1)} {
    background-color: nth($color, 2);
  }
}