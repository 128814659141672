.news-card {
  .card {
    transition: all 0.5s;
    border-width: 0;
  }
  .card-top {
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 71.12%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    .card {
      @extend %box-shadow-1;
    }
  }
}