// ======================================================================== //
//
// Name:            Services
// Description:     Styles specific to the services page
//
// ======================================================================== //

// Variables
// ======================================================================== //

// ======================================================================== //
// Page: Services
// ======================================================================== //

.page-Services {
    .service {
        padding: 190px 0 105px 0;
        @media screen and (max-width: map-get($css-breakpoints, "lg")) {
            padding-top: 240px;
            padding-bottom: 80px;
        }
        @media screen and (max-width: map-get($css-breakpoints, "sm")) {
            padding-top: 160px !important;
            padding-bottom: 10px !important;
        }
        .banner-image {
            right: 0;
            top: 20px;
            left: 45%;
            bottom: 40px;
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                top: 80px;
                left: 0;
                bottom: initial;
                height: 400px;
            }
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                top: 0 !important;
                height: 200px;
            }
        }

        &.first {
            .banner-image {
                bottom: -60px;
            }
        }
        &.even {
            padding: 260px 0 180px 0;
            @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                padding-top: 240px;
                padding-bottom: 80px;
            }
            @media screen and (max-width: map-get($css-breakpoints, "sm")) {
                padding-top: 180px;
            }
            .banner-image {
                left: 0;
                right: 45%;
                top: 140px;
                bottom: 100px;
                @media screen and (max-width: map-get($css-breakpoints, "lg")) {
                    top: 0;
                    right: 0;
                }
            }
            .container > .col-12 {
                background-color: #F6F6F6 !important;
            }
        }
        &.odd {
            &:not(.first) {
                .banner-image {
                    top: -40px;
                }
            }
        }
    }
}
